import React from "react";
import { FormProps } from "./types";
import * as yup from "yup";
import { Input } from "@clear-treasury/design-system";
import { isIbanCountry, validateIBAN } from "./utils";
import { RadioButtonBox } from "#src/core/radio-button-box/RadioButtonBox";

yup.addMethod(yup.string, "iban", function (message) {
  return this.test("iban", message, function (value) {
    if (!value) {
      return false;
    }
    const code = this.parent.country_code;
    return validateIBAN(code, value);
  });
});

export const DefaultSchema = yup.object({
  currency: yup.string().required("Currency is required"),
  country_code: yup.string().required("Country is required"),
  type: yup.string().required("Account type is required"),
  account_name: yup.string().required("Account name is required"),
  account_number: yup.string().when("country_code", {
    is: isIbanCountry,
    then: (schema) => schema.nullable(),
    otherwise: (schema) => schema.required("Account number is required"),
  }),
  iban: yup
    .string()
    .when("country_code", {
      is: (value: string) => !isIbanCountry(value),
      then: (schema) => schema.nullable(),
      otherwise: (schema) =>
        schema
          .iban("Not a valid IBAN. Please check the number and try again")
          .required("Please provide a valid IBAN"),
    })
    .transform((value) => value?.toUpperCase()),
  swift: yup
    .string()
    .matches(
      /^[A-Za-z]{4}[A-Za-z0-9]{4}(?:[A-Za-z0-9]{3})?$/,
      "Please provide a valid SWIFT BIC (it must be 8 or 11 characters in length, first 4 characters must be letters)",
    )
    .transform((value) => value?.toUpperCase()),
  alias: yup.string().optional(),
  email: yup.string().email("Must be a valid email address").optional(),
});

export function DefaultForm({
  values,
  handleChange,
  errors,
  setFieldValue,
}: FormProps): JSX.Element {
  const isIban = isIbanCountry(values.country_code);

  return (
    <>
      <div className="block w-full mb-8">
        <h2 className="mb-5 text-2xl text-theme-color-on-surface">
          Account type
        </h2>

        <div
          role="radiogroup"
          className="grid gap-6 mb-2 md:grid-cols-2 sm:grid-cols-1"
        >
          <RadioButtonBox
            label="Individual"
            name="individual"
            error={errors}
            checked={values.type === "individual"}
            selectHandler={setFieldValue}
            iconUrl="/next/assets/individual.svg"
          />
          <RadioButtonBox
            label="Business"
            name="company"
            error={errors}
            checked={values.type === "company"}
            selectHandler={setFieldValue}
            iconUrl="/next/assets/company.svg"
          />
        </div>

        {errors?.type?.message && (
          <span className="text-theme-color-error text-sm w-full -mt-1 mb-2 block">
            <span className="sr-only">Error: </span>
            {errors.type.message}
          </span>
        )}
      </div>

      <div className="block w-full mb-8">
        <h2 className="text-2xl text-theme-color-on-surface">
          Account details
        </h2>
        <p className="text-gray-500 text-l">
          Please provide the details of the beneficiary acocunt
        </p>
      </div>

      <div className="mb-8">
        <Input
          type="text"
          name="account_name"
          label="Account name"
          placeholder="Enter the beneficiary's account name"
          hint="This should match the name registered on the account you want to pay"
          onChange={handleChange}
          errors={errors}
        />
      </div>

      <div className="mb-8">
        {isIban ? (
          <Input
            type="text"
            name="iban"
            label="IBAN"
            placeholder="Enter IBAN"
            helpText="An IBAN is an international bank account number. It includes the account number plus other mandatory information required for international payments. IBAN must be used when sending funds to specific countries"
            onChange={handleChange}
            errors={errors}
          />
        ) : (
          <Input
            type="text"
            name="account_number"
            label="Account number"
            placeholder="Enter the beneficiary's account number"
            onChange={handleChange}
            errors={errors}
          />
        )}
      </div>

      <div className="mb-8">
        <Input
          type="text"
          name="swift"
          label="SWIFT BIC"
          placeholder="Enter SWIFT BIC"
          onChange={handleChange}
          hint="Must be 8 or 11 alphanumeric characters"
          errors={errors}
        />
      </div>

      <div className="block w-full mb-8">
        <h2 className="text-2xl text-theme-color-on-surface">
          Additional information
        </h2>
      </div>

      <div className="mb-8">
        <Input
          type="text"
          name="alias"
          label="Beneficiary alias (optional)"
          placeholder="Enter a nickname for the beneficiary"
          hint="This is a reference that we will use to display the beneficiary for you"
          onChange={handleChange}
          errors={errors}
        />
      </div>

      <div className="mb-8">
        <Input
          type="text"
          name="email"
          label="Email address (optional)"
          placeholder="Enter an email address for the beneficiary"
          onChange={handleChange}
          errors={errors}
        />
      </div>
    </>
  );
}
