import React from "react";
import { FormProps } from "./types";
import * as yup from "yup";
import { Input } from "@clear-treasury/design-system";
import { RadioButtonBox } from "#src/core/radio-button-box/RadioButtonBox";

export const UsdUsSchema = yup.object().shape(
  {
    currency: yup.string().required("Currency is required"),
    country_code: yup.string().required("Country is required"),
    type: yup.string().required("Account type is required"),
    routing_number: yup
      .string()
      .matches(
        /^[0-9]{9}$/,
        "Please provide a valid ABA Fedwire Code (must be 9 digits exactly)",
      )
      .when("swift", {
        is: (value: string) => !value,
        then: (schema) =>
          schema.required(
            "Please provide either a SWIFT BIC or ABA Fedwire Code or both",
          ),
        otherwise: (schema) => schema.optional(),
      }),

    swift: yup
      .string()
      .matches(
        /^[A-Za-z]{4}[A-Za-z0-9]{4}(?:[A-Za-z0-9]{3})?$/,
        "Please provide a valid SWIFT BIC (it must be 8 or 11 characters in length, first 4 characters must be letters)",
      )
      .transform((value) => value?.toUpperCase())
      .when("routing_number", {
        is: (value: string) => !value,
        then: (schema) =>
          schema.required(
            "Please provide either a SWIFT BIC or ABA Fedwire Code or both",
          ),
        otherwise: (schema) => schema.optional(),
      }),
    bank_name: yup.string().when("swift", {
      is: (value: string) => !value,
      then: (schema) => schema.required("Bank name is required"),
      otherwise: (schema) => schema.optional(),
    }),
    bank_address: yup.string().when("swift", {
      is: (value: string) => !value,
      then: (schema) => schema.required("Bank address is required"),
      otherwise: (schema) => schema.optional(),
    }),
    account_name: yup.string().required("Account name is required"),
    account_number: yup
      .string()
      .required("Account number is required")
      .matches(/^[0-9]+$/, "Must be a number"),
    alias: yup.string().optional(),
    email: yup.string().email("Must be a valid email address").optional(),
  },
  [["routing_number", "swift"]],
);

export function UsdUsForm({
  handleChange,
  errors,
  values,
  setFieldValue,
}: FormProps): JSX.Element {
  return (
    <>
      <div className="block w-full mb-8">
        <h2 className="mb-5 text-2xl text-theme-color-on-surface">
          Account type
        </h2>

        <div
          role="radiogroup"
          className="grid gap-6 mb-2 md:grid-cols-2 sm:grid-cols-1"
        >
          <RadioButtonBox
            label="Individual"
            name="individual"
            error={errors}
            checked={values.type === "individual"}
            selectHandler={setFieldValue}
            iconUrl="/next/assets/individual.svg"
          />
          <RadioButtonBox
            label="Business"
            name="company"
            error={errors}
            checked={values.type === "company"}
            selectHandler={setFieldValue}
            iconUrl="/next/assets/company.svg"
          />
        </div>

        {errors?.type?.message && (
          <span className="text-theme-color-error text-sm w-full -mt-1 mb-2 block">
            <span className="sr-only">Error: </span>
            {errors.type.message}
          </span>
        )}
      </div>

      <div className="block w-full mb-8">
        <h2 className="text-theme-color-on-surface text-2xl">
          Account details
        </h2>

        <p className="text-l text-gray-500">
          Please provide the details of the beneficiary acocunt
        </p>
      </div>

      <div className="mb-8">
        <Input
          type="text"
          name="account_name"
          label="Account name"
          placeholder="Enter the beneficiary's account name"
          hint="This should match the name registered on the account you want to pay"
          onChange={handleChange}
          errors={errors}
        />
      </div>

      <div className="mb-8">
        <Input
          type="text"
          name="account_number"
          label="Account number"
          placeholder="Enter the beneficiary’s account number"
          onChange={handleChange}
          errors={errors}
        />
      </div>

      <div className="mb-8">
        <Input
          type="text"
          name="routing_number"
          label="ABA Fedwire code"
          placeholder="Enter a valid ABA Fedwire code"
          onChange={handleChange}
          hint="Must be 9 digits exactly. You must supply an ABA Fedwire, SWIFT BIC, or both."
          errors={errors}
        />
      </div>

      <div className="mb-8">
        <Input
          type="text"
          name="swift"
          label="SWIFT BIC"
          placeholder="Enter SWIFT BIC"
          onChange={handleChange}
          hint="Must be 8 or 11 alpha-numeric characters. You must supply an ABA Fedwire, SWIFT BIC, or both."
          errors={errors}
        />
      </div>

      {!values.swift && (
        <>
          <div className="mb-8">
            <Input
              type="text"
              name="bank_name"
              label="Bank name"
              hint="You must supply a SWIFT BIC or a bank name."
              placeholder="Enter the bank or payment service provider name"
              onChange={handleChange}
              errors={errors}
            />
          </div>
          <div className="mb-8">
            <Input
              type="text"
              name="bank_address"
              label="Bank address"
              hint="You must supply a SWIFT BIC or a bank address."
              placeholder="Enter the bank or payment service provider address"
              onChange={handleChange}
              errors={errors}
            />
          </div>
        </>
      )}

      <div className="block w-full mb-8">
        <h2 className="text-theme-color-on-surface text-2xl">
          Additional information
        </h2>
      </div>

      <div className="mb-8">
        <Input
          type="text"
          name="alias"
          label="Beneficiary alias (optional)"
          placeholder="Enter a nickname for the beneficiary"
          hint="This is a reference that we will use to display the beneficiary for you"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="mb-8">
        <Input
          type="text"
          name="email"
          label="Email address (optional)"
          placeholder="Enter an email address for the beneficiary"
          onChange={handleChange}
          errors={errors}
        />
      </div>
    </>
  );
}
