import React from "react";
import classNames from "classnames";
import { useSelect, UseSelectStateChange } from "downshift";
import { ChevronDownIcon } from "@heroicons/react/outline";
import FormField, { Error } from "../form-field/FormField";
import Option, { OptionProps } from "./Option";

type Errors = { [name: string]: Error };

interface SelectItem {
  label?: string;
  icon?: React.ReactNode;
  selectedLabel?: string;
}

export type SelectChangeHandler = (
  changes: UseSelectStateChange<OptionProps>,
) => void;

export interface SelectProps {
  name: string;
  hint?: string;
  label?: string;
  options: OptionProps[];
  paired?: string;
  inverted?: boolean;
  helpText?: string;
  helpLabel?: string;
  contained?: boolean;
  placeholder?: string;
  defaultValue?: string;
  errors?: Errors;
  setItem?: OptionProps;
  selectedItemDisplay?: (item: SelectItem) => React.ReactNode;
  onChange?: SelectChangeHandler;
  // TODO: consider adding onBlur at some point for consistency
  // onBlur?: SelectChangeHandler;
}

export type SelectRef = HTMLButtonElement;

const Select = React.forwardRef<SelectRef, SelectProps>((props, ref) => {
  const {
    name,
    hint,
    label,
    options,
    paired,
    inverted,
    helpText,
    helpLabel,
    placeholder,
    defaultValue,
    errors = {},
    contained = true,
    setItem,
    onChange,
  } = props;
  let { selectedItemDisplay } = props;

  let initialSelectedItem: OptionProps = options[0];

  if (placeholder) {
    initialSelectedItem = {
      label: placeholder,
      value: "",
    };
  }

  if (defaultValue || defaultValue === "") {
    initialSelectedItem =
      options.find(({ value }) => value === defaultValue) || options[0];
  }

  const {
    isOpen,
    selectItem,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    id: name,
    items: options,
    itemToString: (item) => item?.label || "",
    initialSelectedItem,
    onSelectedItemChange: onChange,
  });

  selectedItemDisplay = selectedItemDisplay
    ? selectedItemDisplay
    : ({ icon, label } = {}) => (
        <div className="flex space-x-2">
          {icon}
          <span className="truncate pr-6">{label}</span>
        </div>
      );

  React.useEffect(() => {
    if (setItem && setItem.value !== selectedItem?.value) selectItem(setItem);
  }, [setItem?.value]);

  const inputClasses = classNames(
    "relative",
    "form-select",
    "border border-gray-300",
    "focus:ring-teal-100 focus:border-teal-300",
    "disabled:cursor-not-allowed",
    "w-full mb-2 p-3",
    "text-left whitespace-nowrap",
    inverted
      ? "text-theme-color-on-primary bg-theme-color-primary"
      : "bg-white",
    {
      rounded: !paired,
      "rounded-l": paired === "left",
      "rounded-r": paired === "right",
      "border-theme-color-error": errors && errors[name],
      "text-gray-400": !selectedItem?.value, // Placeholder text colour
    },
  );

  const listClasses = classNames("absolute max-h-56 mt-1 overflow-auto z-10", {
    "w-full": contained,
    "rounded border border-gray-300 bg-white focus:ring-teal-100 focus:border-teal-300 disabled:cursor-not-allowed":
      isOpen,
  });

  return (
    <FormField
      hint={hint}
      label={label}
      htmlFor={name}
      relative={contained}
      error={errors[name]}
      helpText={helpText}
      helpLabel={helpLabel}
      {...getLabelProps()}
    >
      <button
        name={name}
        type="button"
        data-testid={label || name}
        value={selectedItem?.value || initialSelectedItem?.value}
        className={inputClasses}
        {...getToggleButtonProps({ ref })}
      >
        {selectedItemDisplay(selectedItem || initialSelectedItem || {})}
        <ChevronDownIcon className="w-5 absolute top-4 right-4 text-gray-400" />
      </button>

      <ul {...getMenuProps()} className={listClasses}>
        {isOpen &&
          options.map((item, index) => (
            <Option
              key={`${item.value}_${index}`}
              isHighlighted={highlightedIndex === index}
              {...getItemProps({ item, index })}
            >
              {item.icon}
              <span>{item.label}</span>
            </Option>
          ))}
      </ul>
    </FormField>
  );
});

Select.displayName = "Select";

export default Select;
