const defaultTheme = require("tailwindcss/defaultTheme");
const designSystem = require("@clear-treasury/design-system/tailwind.config");
const plugin = require("tailwindcss/plugin");

const theme = require("./src/theme/theme");

//@see: https://tailwindcss.com/docs/plugins
//@see: https://stackoverflow.com/questions/6165472/custom-css-scrollbar-for-firefox
const scrollbarHide = plugin(function ({ addUtilities }) {
  addUtilities(
    {
      ".scrollbar-hide": {
        /* IE and Edge */
        "-ms-overflow-style": "none",

        /* Firefox */
        "scrollbar-width": "none",

        /* Safari and Chrome */
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },

      ".scrollbar-default": {
        /* IE and Edge */
        "-ms-overflow-style": "auto",

        /* Firefox */
        "scrollbar-width": "auto",

        /* Safari and Chrome */
        "&::-webkit-scrollbar": {
          display: "block",
        },
      },
    },
    ["responsive"],
  );
});

module.exports = {
  ...designSystem,
  purge: {
    content: [
      "./src/**/*.{js,ts,jsx,tsx}",
      "./node_modules/@clear-treasury/design-system/src/**/*.{js,ts,jsx,tsx}",
    ],
    options: {
      safelist: [/-theme-/],
    },
  },
  theme: {
    ...designSystem.theme,
    screens: {
      xs: "475px", // min-width
      ...defaultTheme.screens,
    },
    extend: {
      ...designSystem.theme.extend,
      fontFamily: {
        "sophia-pro": ["sofia-pro", "serif"],
        "freight-text-pro": ["freight-text-pro", "sans-serif"],
      },
      maxWidth: {
        64: "256px",
      },
      minWidth: {
        64: "256px",
      },
      transitionProperty: {
        "max-height": "max-height",
      },
      colors: {
        ...theme.color,
        neutral: {
          600: "rgb(82 82 82)",
        },
      },
      borderRadius: theme.borderRadius,
      keyframes: {
        stroke: {
          to: {
            strokeDashoffset: 0,
          },
        },
        rotate: {
          "100%": {
            transform: "rotate(180deg)",
          },
        },
        fill: {
          "0%": {
            width: "0%",
          },
          "100%": {
            width: "100%",
          },
        },
      },
      width: {
        "12/25": "49%",
      },
      animation: {
        stroke: "stroke 20s linear forwards",
        loading: "rotate 10s linear both",
        progress: "fill 20s linear 1",
      },
      maxHeight: {
        128: "32rem",
        256: "64rem",
      },
    },
  },
  plugins: [scrollbarHide],
};
