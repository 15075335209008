import { format, parse, isWeekend, isSameDay, addDays } from "date-fns";
import enGb from "date-fns/locale/en-GB";
import { Client } from "src/graphql/gql-types";

export const valueDateReturnFormat = "yyyyMMdd";
export const valueDateDisplayFormat = "dd/MM/yy";

/**
 * Converts input date to locale display format
 *
 * @param date Date
 * @returns Formatted date
 */
const formatDateToDisplay = (date: Date) => {
  return format(date, valueDateDisplayFormat, {
    locale: enGb,
  });
};

/**
 * Converts local date to input format
 */
export const formatDateToInput = (date: Date): string => {
  return format(date, valueDateReturnFormat, {
    locale: enGb,
  });
};

/**
 * Converts a date from one format to another
 *
 * @param date The date to format
 * @param inputFormat The input format
 * @param outputFormat The output format
 * @returns The formatted date
 */
export const transformValueDate = (
  date: string,
  inputFormat: string,
  outputFormat: string,
): string => {
  if (!date) return;

  if (date.includes("-")) {
    inputFormat = "yyyy-MM-dd";
  }

  const parsedDate = parse(date, inputFormat, new Date(), {
    locale: enGb,
  });

  return format(parsedDate, outputFormat, {
    locale: enGb,
  });
};

/**
 * Returns the most immediate weekday date
 *
 * @param businessDate Date
 * @param bankHolidays Array of bank holidays
 * @returns Date of the most immediate weekday
 */
export const getNearestBusinessDate = (
  businessDate: Date,
  bankHolidays: Date[] = [],
  client: Client,
): Date => {
  if (
    bankHolidays.some((bankHoliday) => isSameDay(businessDate, bankHoliday))
  ) {
    return getNearestBusinessDate(
      addDays(businessDate, 1),
      bankHolidays,
      client,
    );
  }

  if (isWeekend(businessDate)) {
    return getNearestBusinessDate(
      addDays(businessDate, 1),
      bankHolidays,
      client,
    );
  }

  return businessDate;
};

const twoDigitTime = (time: number) => time.toString().padStart(2, "0");

/**
 * Returns the next available business day based on 2 currencies
 * formatted for using as the value date for a quote
 *
 * @param sourceCurrency Source currency's code
 * @param targetCurrency Target currency's code
 * @param bankHolidays Array of bank holidays
 * @returns The formatted date of the next available business day
 */
export const getNextAvailableValueDate = (
  sourceCurrencyCutOffTime: string,
  targetCurrencyCutOffTime: string,
  bankHolidays: Date[] = [],
  client: Client,
): string => {
  const date = new Date();
  const now = date
    .toLocaleString("en-GB", {
      timeZone: "Europe/London",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
    .replace(/:/g, "");

  const afterCutoff =
    parseInt(now) > parseInt(sourceCurrencyCutOffTime) ||
    parseInt(now) > parseInt(targetCurrencyCutOffTime);

  let valueDate = new Date();

  if (afterCutoff) {
    valueDate = addDays(valueDate, 1);
  }

  return transformValueDate(
    formatDateToDisplay(
      getNearestBusinessDate(valueDate, bankHolidays, client),
    ),
    valueDateDisplayFormat,
    valueDateReturnFormat,
  );
};

/**
 * Formats a value date for display
 *
 * @param date String in the format yyyyMMdd
 * @returns Localised date string in the format dd/MM/yy
 */
export function formatValueDate(date: string): string {
  const d = `${date.slice(0, 4)}/${date.slice(4, 6)}/${date.slice(6, 8)}`;

  return new Date(d).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}
