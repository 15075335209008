import * as React from "react";
import { useEffect, useState } from "react";
import { useMutation } from "../../hooks/useMutation";
import SelectBeneficiary from "../SelectBeneficiary";
import AddBeneficiaryForm, {
  IAddBeneFormOnDone,
  VerificationStatus,
} from "./add-beneficiary/AddBeneficiaryForm";
import VerificationForm from "./VerificationForm";
import { useApp } from "../../ctx/AppProvider";
import { TOAST_STATUSES, useToast } from "../../ctx/ToastProvider";
import messages from "../../data/i18n/en.json";
import { InformationCircleIcon } from "@heroicons/react/outline";
import Utils from "../../core/Utils";
import {
  Beneficiary,
  BeneficiaryResult,
  Client,
  CreateBeneficiaryDocument,
  CreateBeneficiaryMutationVariables,
  Trade,
} from "../../graphql/gql-types";
import { QuoteWithClientRef } from "../quote-form/QuoteForm";
import { WithRequiredProperty } from "../../lib/UtilityTypes";

export interface IBeneficiaryFormData {
  beneficiary: IAddBeneFormOnDone;
  reason?: string;
  amount?: number;
  payment_reference?: string;
  verification?: VerificationStatus["status"];
}

export interface BeneficiaryFormProps {
  onComplete?: (formData: IBeneficiaryFormData[]) => void;
  onNewBeneficiaryAdded?: () => void;
  selected?: IBeneficiaryFormData[];
  quote: QuoteWithClientRef;
  existingTrade: Trade;
  beneficiaries: WithRequiredProperty<
    Beneficiary,
    "account_name" | "account_number" | "country_code"
  >[];
  beneficiariesLoading: boolean;
  isNewTrade?: boolean;
  isNewBeneficiary?: boolean;
  hasError?: boolean;
}

// TODO We have pretty similar code below as we are storing in: src/pages/beneficiaries.tsx:32. We should remove it!
const BeneficiaryForm = ({
  onComplete,
  onNewBeneficiaryAdded,
  selected,
  existingTrade,
  quote,
  beneficiaries,
  beneficiariesLoading,
  isNewTrade,
  hasError,
  isNewBeneficiary = false,
}: BeneficiaryFormProps): JSX.Element => {
  const [activeClient] = useApp<Client>((store) => store.activeClient);
  const toast = useToast();
  const [addBeneficiary, setAddBeneficiary] =
    useState<boolean>(isNewBeneficiary);
  const [verified, setVerified] = useState<string>();
  const [formData, setFormData] = useState<IBeneficiaryFormData[]>([]);
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState<
    IBeneficiaryFormData[]
  >(selected || []);

  const [newBeneficiaryFormData, setNewBeneficiaryFormData] =
    useState<IBeneficiaryFormData>({
      beneficiary: null,
      verification: null,
    });

  useEffect(() => {
    if (Utils.isNotEmpty(selected)) setSelectedBeneficiaries(selected);
  }, [selected, beneficiariesLoading]);
  const { data: newBeneficiary, error: newBeneficiaryError } = useMutation<
    BeneficiaryResult,
    CreateBeneficiaryMutationVariables["input"]
  >(
    newBeneficiaryFormData.beneficiary && verified
      ? CreateBeneficiaryDocument
      : null,
    {
      ...(newBeneficiaryFormData.beneficiary as CreateBeneficiaryMutationVariables["input"]),
      client_ref: activeClient.cli_reference,
      verification_code: verified,
    },
    () => {
      toast.notify({
        icon: <InformationCircleIcon width="20" />,
        message: messages.beneficiaryAdded.message,
        status: TOAST_STATUSES.POSITIVE,
      });
      setVerified(null);
      setAddBeneficiary(false);
      setNewBeneficiaryFormData({
        beneficiary: null,
      });
    },
    () => {
      setVerified(null);
    },
  );

  // TODO Refactor the errorHandler overall!
  useEffect(() => {
    let errorMessage: string;
    const errorInfo = newBeneficiaryError?.errors[0]?.errorInfo?.Detail;
    if (newBeneficiaryError) {
      errorMessage = messages.newBeneficiary.message;
    }

    // TODO Potentially it could handle another type of error - Fix it soon is possible!
    if (errorInfo) {
      errorMessage = messages.newBeneficiaryWithSameAliases.message;
    }

    if (errorMessage) {
      toast.notify({
        message: errorMessage,
        status: TOAST_STATUSES.CRITICAL,
        context: newBeneficiaryError?.errors[0],
      });
    } else {
      toast.hideNotify();
    }
  }, [newBeneficiaryError]);

  useEffect(() => {
    if (newBeneficiary) {
      setFormData([...formData, newBeneficiaryFormData]);
      onNewBeneficiaryAdded();
    }
  }, [newBeneficiary]);

  if (addBeneficiary) {
    if (newBeneficiaryFormData.beneficiary) {
      return (
        <VerificationForm
          verificationType={newBeneficiaryFormData.verification}
          onCancel={() => {
            setAddBeneficiary(false);
            toast.hideNotify();
          }}
          onComplete={(verifiedCode) => {
            setVerified(verifiedCode);
            toast.hideNotify();
          }}
        />
      );
    }

    return (
      <AddBeneficiaryForm
        currency={quote.currency_buy}
        stepBack={() => setAddBeneficiary(false)}
        onComplete={setNewBeneficiaryFormData}
      />
    );
  }

  return (
    <SelectBeneficiary
      selected={selected}
      existingTrade={existingTrade}
      beneficiaries={beneficiaries}
      beneficiariesLoading={beneficiariesLoading}
      isNewTrade={isNewTrade}
      onComplete={onComplete}
      quote={quote}
      preselected={!isNewTrade}
      selectedBeneficiaries={selectedBeneficiaries}
      setSelectedBeneficiaries={setSelectedBeneficiaries}
      addBeneficiary={() => {
        setNewBeneficiaryFormData({
          beneficiary: null,
          reason: null,
          amount: null,
        });
        setAddBeneficiary(true);
      }}
      disableNextStep={hasError}
    />
  );
};

export default BeneficiaryForm;
