import { RadioButtonBox } from "#src/core/radio-button-box/RadioButtonBox";
import { Button } from "@clear-treasury/design-system";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import React from "react";

type PurposeOfPaymentProps = {
  onGoBack: () => void;
  onContinue: () => void;
  purpose: string;
  setPurpose: (purpose: string) => void;
};

export type PurposeOptionsText =
  | "Paying your other account"
  | "Investment or crypto-currency"
  | "Buying a physical item"
  | "Partner or love interest"
  | "Bill, invoice, or tax"
  | "Friends and family"
  | "Solicitor, rental, or property"
  | "Tradesperson or building work"
  | "Something else";

export type Purpose = {
  text: PurposeOptionsText;
  icon: string;
};

const options: Purpose[] = [
  { text: "Paying your other account", icon: "switch-horizontal.svg" },
  { text: "Investment or crypto-currency", icon: "trending-up.svg" },
  { text: "Buying a physical item", icon: "shopping-cart.svg" },
  { text: "Partner or love interest", icon: "heart.svg" },
  { text: "Bill, invoice, or tax", icon: "document-text.svg" },
  { text: "Friends and family", icon: "users.svg" },
  { text: "Solicitor, rental, or property", icon: "home.svg" },
  { text: "Tradesperson or building work", icon: "truck.svg" },
  { text: "Something else", icon: "question-mark-circle.svg" },
];

export default function PurposeOfPayment({
  onGoBack,
  onContinue,
  purpose,
  setPurpose,
}: PurposeOfPaymentProps) {
  return (
    <>
      <h2 className="text-theme-color-on-surface text-2xl mb-3">
        Purpose of payment
      </h2>
      <p className="text-lg text-gray-500">
        Scams can happen to anyone and they can cause real distress. If someone
        is telling you which option to choose, stop - this is a scam. Don’t be
        pressured into making a quick decision.
      </p>
      <div role="radiogroup" className="grid gap-3 mt-6 grid-cols-1">
        {options.map((option) => (
          <RadioButtonBox
            label={option.text}
            name={option.text}
            checked={purpose === option.text}
            onClick={() => setPurpose(option.text)}
            iconUrl={`/next/assets/${option.icon}`}
            key={option.text}
          />
        ))}
      </div>

      <div className="flex justify-between mt-8">
        <Button onClick={onGoBack} emphasis={Button.Emphasis.TRANSPARENT}>
          <ArrowLeftIcon width="16" />
          Back
        </Button>

        <Button
          size={Button.Size.LARGE}
          disabled={!purpose}
          onClick={onContinue}
        >
          Continue
        </Button>
      </div>
    </>
  );
}
