import { generateClient } from "aws-amplify/api";
import isDeepEqual from "fast-deep-equal/react";
import { getOperationAST } from "graphql";
import * as React from "react";

import type { GraphQLResult } from "@aws-amplify/api-graphql";

type MutationState<Data> = {
  data: Data[];
  loading: boolean;
  error: any;
};

type Input = {
  [name: string]: any;
};

const client = generateClient();

export const useArrayMutation = <Data>(
  query,
  inputData?: Input[],
  onSuccess?: (data?: any) => void,
  onError?: (error: any) => void,
): MutationState<Data> => {
  const [data, setData] = React.useState<Data[] | null>([]);
  const [loading, setLoading] = React.useState<boolean>(!!query);
  const [error, setError] = React.useState(null);

  const inputDataRef = React.useRef(inputData);

  if (!isDeepEqual(inputDataRef.current, inputData)) {
    inputDataRef.current = inputData;
  }

  React.useEffect(() => {
    if (query) {
      (async () => {
        try {
          for (let i = 0; i < inputData.length; i++) {
            setLoading(true);

            const { data } = (await client.graphql({
              query,
              variables: { input: inputData[i] },
            })) as GraphQLResult<Data>;

            const operation = getOperationAST(query);
            const queryName = operation.name.value;

            setData((prevData) => [...prevData, data[queryName]]);
          }

          onSuccess?.(data);
        } catch (err) {
          setError(err);
          onError?.(err);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [query, inputDataRef.current]);

  return {
    data,
    loading,
    error,
  };
};
