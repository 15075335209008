import * as React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  ExclamationIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import { Button, Flag } from "@clear-treasury/design-system";
import Select from "../../../core/select/Select";
import currencies from "../../../data/currencies.json";
import countries from "../../../data/countries.json";
import formTypes from "./form-types";
import { Errors, FormProps } from "./form-types/types";
import { WithRequiredProperty } from "src/lib/UtilityTypes";
import { useApp } from "../../../ctx/AppProvider";
import { isIbanCountry } from "./form-types/utils";
import { useQuery } from "../../../hooks/useQuery";
import Alert from "#src/components/Alert/Alert";
import Utils from "#src/core/Utils";
import { VerifyBeneficiaryDocument } from "src/graphql/gql-types";
import PurposeOfPayment, {
  PurposeOptionsText,
} from "#src/components/PurposeOfPayment/PurposeOfPayment";
import WarnForScam from "#src/components/WarnForScam/WarnForScam";

import type {
  Beneficiary,
  BeneficiaryVerificationResult,
  Client,
  CreateBeneficiaryInput,
  QueryVerifyBeneficiaryArgs,
  VerifyBeneficiaryQuery,
} from "src/graphql/gql-types";

export type IAddBeneFormOnDone = WithRequiredProperty<
  Partial<CreateBeneficiaryInput>,
  "country_code" | "account_name"
> & {
  isIfBeneficiary?: boolean;
  id?: Beneficiary["id"];
};

type BeneficiaryScreen =
  | "Purpose of Payment"
  | "Warn for Scam"
  | "Add Beneficiary";

export interface AddBeneficiaryProps {
  currency?: string;
  country?: string; // coutnry code in ISO2 format
  stepBack?: (stepNumber: number) => void;
  // TODO: graphql schema needs to be fixed as there is inconsistency in the types
  // for now we are using any
  onComplete?: (args: {
    beneficiary: IAddBeneFormOnDone;
    verification: VerificationStatus["status"] | null;
  }) => void;
}

const getCurrency = (CurrencyCode: string) => ({
  value: CurrencyCode,
  label: `${CurrencyCode}`,
  selectedLabel: CurrencyCode,
  icon: <Flag country={CurrencyCode.slice(0, -1).toLowerCase()} />,
});

const countriesList = countries
  .filter(({ RiskScore }) => !(RiskScore > 0))
  .map(({ CountryName, ISO2 }) => ({
    value: ISO2,
    label: `${CountryName}`,
    selectedLabel: CountryName,
    icon: <Flag country={ISO2.toLowerCase()} />,
  }));

const getCountry = (ISO2?: string): string | undefined => {
  if (!ISO2) return undefined;

  const country = countriesList.find((c) => c.value === ISO2);

  if (!country) throw new Error(`Country ${ISO2} not found`);

  return country.value;
};

const defaultValues = {
  currency: "GBP",
  country_code: "GB",
};

export type VerificationStatus = {
  status: "full_match" | "no_match" | "close_match" | "requesting";
  actual_name?: BeneficiaryVerificationResult["actual_name"];
  reason_code?: BeneficiaryVerificationResult["reason_type"];
};

const AlertMessage = ({
  reason_code,
  actual_name,
}: {
  reason_code: string;
  actual_name?: string;
}) => {
  switch (reason_code) {
    case "AV300":
      return (
        <div>
          <p className="mb-4 font-bold">Incorrect beneficiary name</p>
          <p>
            The details closely match our records, but the beneficiary name is{" "}
            <span className="font-bold">{actual_name}</span>. Please verify and{" "}
            update the name if needed, or click &apos;Continue&apos; if
            you&apos;re sure the details are correct.
          </p>
        </div>
      );
    case "AV301":
    case "AV302":
      return (
        <div>
          <p className="mb-4 font-bold">Incorrect beneficiary name and type</p>
          <p>
            The details closely match our records, but the beneficiary name is{" "}
            <span className="font-bold">{actual_name}</span> and the beneficiary
            type is different. Please verify and update the name and type if
            needed, or click &apos;Continue&apos; if you&apos;re sure the
            details are correct.
          </p>
        </div>
      );
    case "AV303":
      return (
        <div>
          <p className="mb-4 font-bold">Incorrect beneficiary type</p>
          <p>
            The details closely match our records, but the beneficiary type is
            business not individual. Please verify and update the type if
            needed, or click &apos;Continue&apos; if you&apos;re sure the
            details are correct.
          </p>
        </div>
      );
    case "AV304":
      return (
        <div>
          <p className="mb-4 font-bold">Incorrect beneficiary type</p>
          <p>
            The details closely match our records, but the beneficiary type is
            individual not business. Please verify and update the type if
            needed, or click &apos;Continue&apos; if you&apos;re sure the
            details are correct.
          </p>
        </div>
      );
    case "AV305":
      return (
        <div>
          <p className="mb-4 font-bold">Account has been switched</p>
          <p>
            The details closely match our records, but the account has been
            switched to a different organisation. Please verify and update the
            details, or click &apos;Continue&apos; if you&apos;re sure the
            details are correct.
          </p>
        </div>
      );
    case "AV200":
      return (
        <div>
          <p className="mb-4 font-bold">Unable to confirm account details</p>
          <p>
            The account number and sort code do not match those on record.
            Please verify and update the details, or click &apos;Continue&apos;
            if you&apos;re sure the details are correct.
          </p>
        </div>
      );
    case "AV201":
      return (
        <div>
          <p className="mb-4 font-bold">No match for beneficiary name</p>
          <p>
            The name you have provided for the beneficiary does not match that
            on record. Please verify and update the details, or click
            &apos;Continue&apos; if you&apos;re sure the details are correct.
          </p>
        </div>
      );
    case "AV202":
    case "AV203":
    case "AV204":
    case "AV205":
    case "AV207":
      return (
        <div>
          <p className="mb-4 font-bold">Unable to check provided details</p>
          <p>
            It has not been possible to check the beneficiary details you have
            provided. Please verify and update the details, or click
            &apos;Continue&apos; if you&apos;re sure the details are correct.
          </p>
        </div>
      );
    case "AV206":
      return (
        <div>
          <p className="mb-4 font-bold">No match for provided details</p>
          <p>
            The reference details you have provided for the beneficiary do not
            match those on record. Please verify and update the details, or
            click &apos;Continue&apos; if you&apos;re sure the details are
            correct.
          </p>
        </div>
      );
    default:
      return null;
  }
};

const AddBeneficiaryForm = ({
  currency: ccy,
  country,
  stepBack,
  onComplete,
}: AddBeneficiaryProps): JSX.Element => {
  const [activeClient] = useApp<Client>((store) => store.activeClient);

  const currencyRef = React.useRef<HTMLButtonElement | null>(null);
  const [currency, setCurrency] = React.useState<string>(
    ccy || defaultValues.currency
  );
  const [countryCode, setCountryCode] = React.useState<string>(
    country || defaultValues.country_code
  );

  const [beneficiaryValidation, setBeneficiaryValidation] =
    React.useState<VerificationStatus | null>(null);

  const [purposeOfPayment, setPurposeOfPayment] =
    React.useState<PurposeOptionsText>();
  const [currentScreen, setCurrentScreen] =
    React.useState<BeneficiaryScreen>("Purpose of Payment");

  const initialValues = {
    country_code: countryCode,
    email: "",
    alias: "",
    account_name: "",
    account_number: "",
    sort_code: "",
    first_name: "",
    last_name: "",
    bank_name: "",
    ben_address: "",
    ben_address_post_code: "",
    ben_country_code: "",
    currency,
    type: "",
    secondary_reference_data: "",
    payment_purpose: "",
  };

  const [Form, Schema] = getFormComponentAndSchema(
    currency,
    countryCode,
    activeClient.is_EEA
  );
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    validateOnChange: false,
    onSubmit: (values) => {
      if (
        values.type === "individual" &&
        values.first_name &&
        values.last_name
      ) {
        values.account_name = `${values.first_name} ${values.last_name}`;
      }

      delete values.first_name;
      delete values.last_name;

      values.payment_purpose = purposeOfPayment;

      onComplete({
        beneficiary: values,
        verification: beneficiaryValidation?.status,
      });
    },
  });

  const { isLoading } = useQuery<
    BeneficiaryVerificationResult,
    VerifyBeneficiaryQuery,
    QueryVerifyBeneficiaryArgs
  >(
    // Only verify the beneficiary if the client is in the EEA
    activeClient.is_EEA &&
      beneficiaryValidation?.status === "requesting" &&
      currency === "GBP" &&
      countryCode === "GB"
      ? VerifyBeneficiaryDocument
      : null,
    {
      client_ref: activeClient && activeClient.cli_reference,
      account_number: formik.values.account_number,
      sort_code: formik.values.sort_code,
      beneficiary_type: formik.values.type,
      beneficiary_company_name:
        formik.values.type === "company"
          ? formik.values.account_name
          : undefined,
      beneficiary_first_name:
        formik.values.type === "individual"
          ? formik.values.first_name
          : undefined,
      beneficiary_last_name:
        formik.values.type === "individual"
          ? formik.values.last_name
          : undefined,
      secondary_reference_data:
        formik.values.type === "company" &&
        formik.values.secondary_reference_data
          ? formik.values.secondary_reference_data
          : undefined,
    },
    [],
    (data) => {
      setBeneficiaryValidation({
        status: data?.answer as VerificationStatus["status"],
        actual_name: data?.actual_name,
        reason_code: data?.reason_code,
      });

      if (data?.answer === "full_match") {
        setTimeout(() => {
          onComplete({
            beneficiary: formik.values,
            verification: data?.answer as VerificationStatus["status"],
          });
        }, 2000);
      }
    }
  );

  React.useEffect(() => {
    if (["close_match", "no_match"].includes(beneficiaryValidation?.status)) {
      setBeneficiaryValidation(null);
    }
  }, [
    formik.values.type,
    formik.values.account_name,
    formik.values.first_name,
    formik.values.last_name,
  ]);

  const currencyList =
    activeClient.cty_value === "PRIVATE"
      ? currencies.privateClient.map(({ CurrencyCode }) =>
          getCurrency(CurrencyCode)
        )
      : currencies.corporateClient.map(({ CurrencyCode }) =>
          getCurrency(CurrencyCode)
        );

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validation = await formik.validateForm();

    // Stop if there are validation errors
    if (Utils.isNotEmpty(validation)) {
      return false;
    }

    // If the client is not in the EEA, we can submit the form
    if (!activeClient.is_EEA) {
      return formik.handleSubmit(e);
    }

    if (activeClient.is_EEA && !beneficiaryValidation && currency === "GBP") {
      setBeneficiaryValidation({ status: "requesting" });
    } else {
      formik.handleSubmit(e);
    }
  };

  if (currentScreen === "Purpose of Payment") {
    return (
      <PurposeOfPayment
        onGoBack={() => stepBack(0)}
        onContinue={() => {
          if (purposeOfPayment) {
            setCurrentScreen("Warn for Scam");
          }
        }}
        purpose={purposeOfPayment}
        setPurpose={(purpose: PurposeOptionsText) =>
          setPurposeOfPayment(purpose)
        }
      />
    );
  }

  if (currentScreen === "Warn for Scam") {
    return (
      <WarnForScam
        onGoBack={() => setCurrentScreen("Purpose of Payment")}
        onContinue={() => setCurrentScreen("Add Beneficiary")}
        purpose={purposeOfPayment}
      />
    );
  }

  if (currentScreen === "Add Beneficiary") {
    return (
      <form onSubmit={submitHandler}>
        <div
          data-testid="beneficiary-bank-location"
          className="grid gap-6 mb-8 md:grid-cols-2 sm:grid-cols-1"
        >
          <Select
            name="currency"
            ref={currencyRef}
            label="Currency"
            options={ccy ? [getCurrency(ccy)] : currencyList}
            defaultValue={currency || defaultValues.currency}
            onChange={(e) => {
              setCurrency(e.selectedItem.value);
              formik.setFieldValue("currency", e.selectedItem.value, false);
            }}
          />

          <Select
            name="country_code"
            label="Destination country"
            options={countriesList}
            helpLabel="What is this?"
            helpText="The country that the beneficiaries bank or payment service provider is located in"
            onChange={(e) => {
              setCountryCode(e.selectedItem.value);
              formik.setFieldValue("country_code", e.selectedItem.value, false);
            }}
            defaultValue={getCountry(country) || defaultValues.country_code}
          />
        </div>

        <Form
          values={formik.values}
          handleChange={formik.handleChange}
          errors={transformErrors(formik.errors)}
          setFieldValue={(key, val) => {
            formik.setFieldValue(key, val);
          }}
        />

        {beneficiaryValidation?.status === "close_match" && (
          <Alert status={Alert.Status.CAUTION} icon={ExclamationIcon}>
            <AlertMessage
              reason_code={beneficiaryValidation.reason_code}
              actual_name={beneficiaryValidation.actual_name}
            />
          </Alert>
        )}

        {beneficiaryValidation?.status === "no_match" && (
          <Alert status={Alert.Status.CRITICAL} icon={XCircleIcon}>
            <AlertMessage reason_code={beneficiaryValidation.reason_code} />
          </Alert>
        )}
        {beneficiaryValidation?.status === "full_match" && (
          <Alert
            status={Alert.Status.POSITIVE}
            icon={CheckCircleIcon}
            text="Beneficiary details confirmed"
          />
        )}

        <div className="flex justify-between mt-8">
          <Button
            onClick={() => stepBack(0)}
            emphasis={Button.Emphasis.TRANSPARENT}
          >
            <ArrowLeftIcon width="16" />
            Back
          </Button>

          <Button
            typeof="submit"
            size={Button.Size.LARGE}
            loading={
              isLoading || beneficiaryValidation?.status === "full_match"
            }
            disabled={
              isLoading || beneficiaryValidation?.status === "full_match"
            }
          >
            Continue
          </Button>
        </div>
      </form>
    );
  }
};

export default AddBeneficiaryForm;

function transformErrors(errors: Record<string, string>): Errors {
  return Object.keys(errors).reduce((acc, key) => {
    acc[key] = { message: errors[key] };
    return acc;
  }, {} as Errors);
}

function getFormComponentAndSchema(
  currency: string,
  countryCode: string,
  is_EEA: boolean
): [React.FC<FormProps>, yup.ObjectSchema<any>] {
  if (currency === "GBP" && countryCode === "GB") {
    const schema = formTypes["GBP/GB"].schema;

    if (!is_EEA) {
      schema.fields.account_name = yup
        .string()
        .required("Account name is required");
      schema.fields.first_name = yup.string();
      schema.fields.last_name = yup.string();
    }

    return [formTypes["GBP/GB"].form, schema];
  }
  if (["AU", "CA", "IN", "PK", "PL", "CN"].includes(countryCode)) {
    return [formTypes[countryCode].form, formTypes[countryCode].schema];
  }
  if (currency === "EUR" && isIbanCountry(countryCode)) {
    return [formTypes["EUR/EEA"].form, formTypes["EUR/EEA"].schema];
  }
  if (currency === "USD" && countryCode === "US") {
    return [formTypes["USD/US"].form, formTypes["USD/US"].schema];
  }

  return [formTypes["Default"].form, formTypes["Default"].schema];
}
