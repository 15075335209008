// TODO: Temporarily using this 3rd party component until our design system Countdown unmounting issues are fixed
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import React from "react";

export interface QuoteCountdownProps {
  _key: string;
  timestamp: number;
  isPlaying: boolean;
  onComplete: () => void;
  disabled?: boolean;
}

export const REQUOTE_INTERVAL_SECS = 30;

const getQuoteCounterTime = (timestamp: number): number => {
  const value =
    REQUOTE_INTERVAL_SECS - Math.round((Date.now() - timestamp) / 1000);

  if (value > 0) return value;

  return REQUOTE_INTERVAL_SECS;
};

const QuoteCountdown: React.FC<QuoteCountdownProps> = ({
  timestamp,
  _key,
  isPlaying,
  onComplete,
  disabled,
}) => {
  const remainingCounterTime = getQuoteCounterTime(timestamp);

  return (
    <CountdownCircleTimer
      key={_key}
      size={36}
      strokeWidth={2}
      duration={disabled ? 0 : REQUOTE_INTERVAL_SECS}
      initialRemainingTime={remainingCounterTime}
      isPlaying={!disabled && isPlaying}
      onComplete={onComplete}
      colors={
        disabled
          ? "#D9D9D9"
          : [
              ["#01A783", 0.5],
              ["#E6AE05", 0.25],
              ["#FF713D", 0.25],
            ]
      }
    />
  );
};

export default QuoteCountdown;
