import * as React from "react";
import Image from "next/image";
import { Errors } from "src/core/input/Input";
import Icon from "./Icon";
import { FormProps } from "#src/components/beneficiary-form/add-beneficiary/form-types/types";

interface RadioButtonBoxProps extends React.ComponentPropsWithoutRef<"input"> {
  label: string;
  name: string;
  iconUrl?: string;
  error?: Errors;
  errorKey?: string;
  selectHandler?: FormProps["setFieldValue"];
  isInvalid?: boolean;
}

const entityTypeMappings = {
  Business: "company",
  Individual: "individual",
};

export const RadioButtonBox = ({
  checked,
  label,
  name,
  iconUrl,
  error = {},
  selectHandler,
  onClick,
}: RadioButtonBoxProps): JSX.Element => {
  return (
    <div
      role="radio"
      aria-checked={checked}
      data-value={entityTypeMappings[name]}
      aria-labelledby={`beneficiary-type-${name}`}
      onClick={selectHandler ? () => selectHandler("type", name) : onClick}
      className={`flex items-center justify-between px-5 py-4 relative border border-gray-300 rounded cursor-pointer ${
        checked && "bg-gray-100"
      }${error && error["type"] ? " border-theme-color-error" : ""}`}
    >
      <div className="flex items-center">
        <div
          data-client-type={`${label.toLowerCase()}`}
          className={`rounded-full h-6 w-6 flex items-center justify-center bg-transparent ${
            checked ? "bg-green-600" : "border border-gray-400"
          }`}
        >
          {checked && <Icon icon="TICK" size={18} color="white" />}
        </div>

        <span id={`beneficiary-type-${name}`} className="pl-2 font-sans">
          {label}
        </span>
      </div>

      {iconUrl && (
        <Image src={iconUrl} alt="Entity Type" width={32} height={32} />
      )}
    </div>
  );
};
