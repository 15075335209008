import { useState } from "react";
import Alert from "../Alert/Alert";
import { InformationCircleIcon } from "@heroicons/react/outline";
import Icon from "#src/core/radio-button-box/Icon";
import { Button } from "@clear-treasury/design-system";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { PurposeOptionsText } from "../PurposeOfPayment/PurposeOfPayment";

type WarnForScamProps = {
  onGoBack: () => void;
  onContinue: () => void;
  purpose: PurposeOptionsText;
};

const AlertMessage = ({ purpose }: { purpose: PurposeOptionsText }) => {
  switch (purpose) {
    case "Paying your other account":
      return (
        <p>
          If someone tells you that you need to &apos;keep your money safe&apos;
          by moving it to another account, this is definitely a scam. Don&apos;t
          proceed.
        </p>
      );
    case "Investment or crypto-currency":
      return (
        <p>
          Avoid investment scams by using the FCA ScamSmart fool to check out
          for their listed warning signs. It the company is genuine, search the
          FCA website to check if it hasn&apos;t been impersonated by scammers
        </p>
      );
    case "Bill, invoice, or tax":
      return (
        <p>
          Call the company or person directly to confirm the request and account
          details before you pay. Use a number from a trusted source like an
          official website, not the one listed on an invoice or email.
        </p>
      );
    case "Buying a physical item":
      return (
        <p>
          Be suspicious if you&apos;ve been asked to pay by bank transfer.
          Paying through the company&apos;s official app or website may give you
          more protection
        </p>
      );
    case "Friends and family":
      return (
        <p>
          Contact the person or company on a known and trusted number to check
          that their payment request is genuine.
        </p>
      );
    case "Partner or love interest":
      return (
        <p>
          Don&apos;t send money to anyone you&apos;ve never met in person - even
          if you&apos;re in a romantic relationship or have been speaking to
          them for a while.
        </p>
      );
    case "Solicitor, rental, or property":
      return (
        <p>
          Call the company directly to confirm the account details before you
          pay. If they&apos;ve called you, phone them back. Use a number from a
          trusted source like an official website - not the one listed on an
          invoice, email or direct message.
        </p>
      );
    case "Something else":
      return (
        <p>
          If someone claims to be from a bank, the police or a well-known
          company and tells you to move money out of your account, stop - this
          is a scam.
        </p>
      );
    case "Tradesperson or building work":
      return (
        <p>
          Don&apos;t pay a tradesperson betore any work has been carried out on
          your home. Be cautious of any cold-calls or visits.
        </p>
      );
    default:
      return null;
  }
};

export default function WarnForScam({
  onGoBack,
  onContinue,
  purpose,
}: WarnForScamProps) {
  const [checked, setChecked] = useState<boolean>();

  return (
    <>
      <h2 className="text-theme-color-on-surface text-2xl mb-6">
        {purpose} - Could this be a scam?
      </h2>
      <Alert status={Alert.Status.PRIMARY} icon={InformationCircleIcon}>
        <AlertMessage purpose={purpose} />
      </Alert>
      <p className="text-lg text-gray-500 mb-6 mt-6">
        No legitimate company or organisation will ever ask you to move money
        out of your account.
      </p>
      <p className="text-lg text-gray-500 mb-6">
        Scammers can also copy telephone numbers to appear legitimate, so always
        phone back on a trusted number you’ve found on an official website.
      </p>
      <div className="flex items-center space-x-4">
        <div
          role="checkbox"
          id="scam-warning"
          aria-checked={checked}
          onClick={() => setChecked(!checked)}
          className={`h-5 w-5 ${
            checked ? "" : "border"
          } border-gray-300 rounded cursor-pointer`}
        >
          <div
            className={`h-5 w-5 rounded flex items-center justify-center bg-transparent ${
              checked && "bg-green-600"
            }`}
          >
            {checked && <Icon icon="TICK" size={16} color="white" />}
          </div>
        </div>
        <label htmlFor="scam-warning">
          I&apos;ve read the warning and completed checks on the payment and
          payee. I understand I could lose my money if this is a scam.
        </label>
      </div>
      <div className="flex justify-between mt-8">
        <Button emphasis={Button.Emphasis.TRANSPARENT} onClick={onGoBack}>
          <ArrowLeftIcon width="16" />
          Back
        </Button>

        <Button
          size={Button.Size.LARGE}
          disabled={!checked}
          onClick={onContinue}
        >
          Continue
        </Button>
      </div>
    </>
  );
}
