import * as React from "react";
import { FC, useEffect, useState } from "react";

import { Flag, Input } from "@clear-treasury/design-system";
import { Error } from "@clear-treasury/design-system/dist/components/form-field/FormField";
import { CreditCardIcon } from "@heroicons/react/outline";
import Utils from "../../core/Utils";
import reasons from "../../data/reasons.json";
import BalanceLabel from "../BalanceLabel/BalanceLabel";
import Select from "../Select/Select";
import { IBeneficiaryFormData } from "../beneficiary-form/BeneficiaryForm";
import Toggle from "../toggle/Toggle";
import { Beneficiary } from "src/graphql/gql-types";
import { WithRequiredProperty } from "src/lib/UtilityTypes";

export type Errors = {
  form?: Error;
  [fieldName: string]: Error;
};

export interface ISelectBeneficiaryRowProps {
  item: Beneficiary;
  isActive: boolean;
  errors: Errors;
  setErrors: React.Dispatch<any>;
  setAccAmount: React.Dispatch<any>;
  toggleFocus: (id: string | null) => void;
  clientType: string;
  accAmount: number;
  tradeAmount: number;
  preselected?: boolean;
  beneficiaries: WithRequiredProperty<
    Beneficiary,
    "country_code" | "account_name"
  >[];
  selectedBeneficiary: IBeneficiaryFormData;
  selectedBeneficiaries: IBeneficiaryFormData[];
  setSelectedBeneficiaries: React.Dispatch<
    React.SetStateAction<IBeneficiaryFormData[]>
  >;
  updateSelectedBeneficiary: (
    id: string,
    update: Partial<IBeneficiaryFormData>,
  ) => void;
}

const SelectBeneficiaryRow: FC<ISelectBeneficiaryRowProps> = ({
  item,
  errors,
  isActive,
  setErrors,
  setAccAmount,
  clientType,
  accAmount,
  tradeAmount,
  preselected = false,
  beneficiaries,
  setSelectedBeneficiaries,
  selectedBeneficiaries,
  updateSelectedBeneficiary,
  selectedBeneficiary,
  toggleFocus,
}): JSX.Element => {
  const selectedBeneficiaryIds = selectedBeneficiaries.map(
    ({ beneficiary }) => beneficiary?.id,
  );
  const [amount, setAmount] = useState<string>("");

  const calculateCurrentAccAmount = () => {
    const sum =
      selectedBeneficiaries.reduce((acc, curr) => (acc += curr.amount), 0) || 0;

    const reminder = tradeAmount - sum < 0 ? 0 : tradeAmount - sum;
    setAccAmount(reminder);
  };

  useEffect(() => {
    if (selectedBeneficiary) {
      setAmount(
        selectedBeneficiary.amount
          ? Utils.parseAmountByCurrencyCode(
              selectedBeneficiary.amount,
              item.currency,
            )
          : "",
      );
    }
  }, [selectedBeneficiary?.amount, selectedBeneficiaries]);

  return (
    <div
      className="bg-gray-100"
      role="beneficiary-item" // TODO: Do we need this role? Is it in use anywhere?
      data-test-id="beneficiary-item"
      key={item.id}
    >
      <div className="grid grid-cols-4 gap-1 border-b border-gray-200 p-4">
        <div className="flex col-start-1 col-end-4 space-x-4 items-center">
          <div>
            <div className="max-h-11">
              <Flag
                size="lg"
                country={item.currency.slice(0, -1).toLowerCase()}
              />
            </div>

            <p className="text-base text-gray-600 text-center">
              {item.currency}
            </p>
          </div>

          <div>
            <span className="text-lg text-gray-600">{item.account_name}</span>

            <div className="flex space-x-2">
              <CreditCardIcon width="18" className="text-gray-400" />

              <span className="text-gray-400 items-start text-sm">
                {item.iban || item.account_number}
              </span>
            </div>
          </div>
        </div>

        {!preselected && (
          <div className="col-span-1 flex justify-end flex-wrap content-center">
            <Toggle
              id={`${item.id}`}
              checked={selectedBeneficiaryIds.indexOf(item.id) !== -1}
              onChange={() => {
                const newErrors = { ...errors };
                delete newErrors[`amount-${item.id}`];
                delete newErrors[`reason-${item.id}`];
                delete newErrors[`reference-${item.id}`];
                setErrors(newErrors);

                toggleFocus(null);
                if (selectedBeneficiaryIds.indexOf(item.id) === -1) {
                  const newlySelectedBeneficiary: IBeneficiaryFormData = {
                    beneficiary: beneficiaries.find(({ id }) => item.id === id),
                    amount: 0,
                    reason: null,
                  };

                  setSelectedBeneficiaries([
                    ...selectedBeneficiaries,
                    newlySelectedBeneficiary,
                  ]);

                  calculateCurrentAccAmount();
                } else {
                  setSelectedBeneficiaries(
                    selectedBeneficiaries.filter(({ beneficiary, amount }) => {
                      if (beneficiary.id === item.id) {
                        setAccAmount(accAmount + amount);
                      }

                      return beneficiary.id !== item.id;
                    }),
                  );
                }
              }}
            />
          </div>
        )}
      </div>

      {selectedBeneficiaryIds.indexOf(item.id) !== -1 && (
        <div className="p-4 grid grid-cols-8 gap-6">
          <div className="col-span-5">
            <Select
              name={`reason-${item.id}`}
              errors={errors}
              label="Reason for transfer"
              options={reasons[clientType]}
              defaultValue={
                selectedBeneficiaryIds.indexOf(item.id) !== -1 &&
                selectedBeneficiaries.find(
                  ({ beneficiary }) => beneficiary.id === item.id,
                )?.reason
              }
              placeholder="Select an option"
              onChange={(option) => {
                updateSelectedBeneficiary(item.id, {
                  reason: option.selectedItem.value,
                });
              }}
            />
            <div className="text-gray-600 text-sm">
              Please provide a reason for your payments to this beneficiary
            </div>
          </div>

          <div className="col-span-3">
            <Input
              label="Amount"
              name={`amount-${item.id}`}
              placeholder="Enter an amount"
              data-testid="amount"
              value={amount}
              onFocus={() => {
                if (accAmount > 0) delete errors[`amount-${item.id}`];

                toggleFocus(item.id);
                calculateCurrentAccAmount();
              }}
              onKeyPress={(event) => {
                // Only allow numbers and decimal points to be entered
                if (!/[0-9.]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => setAmount(event.target.value)}
              onBlur={(event) => {
                const amount = +parseFloat(
                  event.target.value.replace(/,/g, "") || "0",
                ).toFixed(2);

                updateSelectedBeneficiary(item.id, {
                  amount,
                });
              }}
            />

            {isActive && (
              <BalanceLabel
                hasOnly={Utils.isNotEmpty(errors[`amount-${item.id}`])}
                hasError={typeof errors[`amount-${item.id}`] === "object"}
                amount={
                  selectedBeneficiaries.length > 1 &&
                  Utils.isEmpty(errors[`amount-${item.id}`]) &&
                  (accAmount || accAmount === 0)
                    ? accAmount
                    : tradeAmount
                }
                currency={item.currency}
              />
            )}
          </div>
          <div className="col-span-8">
            <Input
              data-testid="reference"
              type="text"
              value={selectedBeneficiary?.payment_reference || ""}
              name={`reference-${item.id}`}
              label="Reference"
              placeholder="Enter information for the beneficiary"
              errors={errors}
              onChange={(event) => {
                updateSelectedBeneficiary(item.id, {
                  payment_reference: event.target.value,
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectBeneficiaryRow;
