import React from "react";
import classNames from "classnames";

export type OptionProps = {
  children?: React.ReactNode;
  label?: string;
  value?: string;
  icon?: React.ReactNode;
  isHighlighted?: boolean;
};

type Ref = HTMLLIElement;

const Option = React.forwardRef<Ref, OptionProps>(
  ({ isHighlighted, children, ...props }, ref) => {
    const itemClasses = classNames(
      "cursor-default select-none relative py-2 px-3 flex space-x-2 items-center",
      isHighlighted ? "bg-teal-50" : "text-navy-600",
    );

    return (
      <li {...props} ref={ref} className={itemClasses}>
        {children}
      </li>
    );
  },
);

Option.displayName = "Option";

export default Option;
