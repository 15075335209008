import { getCurrentUser } from "aws-amplify/auth/server";
import { useRouter } from "next/router";
import * as React from "react";
import BeneficiaryForm from "../components/beneficiary-form/BeneficiaryForm";
import ConfirmPayForm from "../components/confirm-pay-form/ConfirmPayForm";
import PaymentDetails from "../components/confirm-pay-form/PaymentDetails";
import Page from "../components/page/Page";
import QuoteForm from "../components/quote-form/QuoteForm";
import Steps from "../components/steps/Steps";
import Tab from "../components/steps/Tab";
import Utils from "../core/Utils";
import { useApp } from "../ctx/AppProvider";
import { TOAST_STATUSES, useToast } from "../ctx/ToastProvider";
import {
  GetBeneficiariesDocument,
  GetCurrenciesDocument,
  GetTradesDocument,
} from "../graphql/gql-types";
import useQuery from "../hooks/useQuery";
import { serverSideProps, withSSRContext } from "../lib/withSSRContext";

import type { GetServerSideProps } from "next";
import type { WithRequiredProperty } from "src/lib/UtilityTypes";
import type { IBeneficiaryFormData } from "../components/beneficiary-form/BeneficiaryForm";
import type { QuoteWithClientRef } from "../components/quote-form/QuoteForm";
import type {
  Balance,
  BeneficiariesResult,
  Beneficiary,
  Client,
  GetBeneficiariesQuery,
  GetBeneficiariesQueryVariables,
  GetCurrenciesQuery,
  GetCurrenciesQueryVariables,
  GetTradesQuery,
  GetTradesQueryVariables,
  Trade,
} from "../graphql/gql-types";

import errors from "../data/i18n/en.json";

export type TransferData = {
  quote?: QuoteWithClientRef;
  beneficiaries?: IBeneficiaryFormData[];
  reason?: string;
  trade?: Trade;
};

export enum DebitSource {
  Balance = "Balance",
  Bank = "Bank",
  Account = "Account",
}

export enum BeneficiarySource {
  Bank = "Bank",
  IntegratedFinance = "IntegratedFinance",
}

export enum TradeDirection {
  BUY = "BUY",
  SELL = "SELL",
}

interface Props {
  authenticated: boolean;
  isCrossBorderAccountTransfersEnabled: boolean;
}

// eslint-disable-next-line no-empty-pattern
const Transfer = ({}: Props): JSX.Element => {
  const router = useRouter();
  const { query } = router;
  const [activeClient] = useApp<Client>((store) => store.activeClient);
  const toast = useToast();

  const [step, setStep] = React.useState<number>(
    Utils.isNotEmpty(query?.instructPaymentTradeID) ? 1 : 0,
  );
  const [formData, setFormData] = React.useState<TransferData>({});
  const [loading, setLoading] = React.useState(true);
  const [debitSource, setDebitSource] = React.useState<DebitSource>();
  const [selectedBalance, setSelectedBalance] =
    React.useState<
      WithRequiredProperty<Partial<Balance>, "balance" | "currency">
    >();
  const [isNewTrade] = React.useState(
    Utils.isEmpty(query?.instructPaymentTradeID) ||
      Utils.isNotEmpty(query?.balance_currency),
  );

  const [beneficiary, setBeneficiary] = React.useState<Beneficiary>();
  const [newBeneficiaryCount, setNewBeneficiaryCount] = React.useState(0);
  //TODO: Remove it!
  const shouldFetchTrades =
    activeClient && (beneficiary || query?.instructPaymentTradeID);

  const { isLoading: transactionsLoading, error: tradeListFetchError } =
    useQuery<
      GetTradesQuery["getTrades"],
      GetTradesQuery,
      GetTradesQueryVariables
    >(
      shouldFetchTrades ? GetTradesDocument : null,
      {
        client_ref: activeClient?.cli_reference,
        limit: 100,
        currency:
          (query?.targetCurrency as string) || // TODO:: (PAY-127) potentially dangerous if the value could be string[]
          beneficiary?.currency ||
          (router?.query?.balance_currency as string), // TODO:: (PAY-127) potentially dangerous if the value could be string[]
        // direction: TradeDirection.BUY, // TODO:: (PAY-127) Passed as data to an argument that is not part of the query! See src/graphql/trades/trades.graphql
        filter: router?.query?.balance_currency && {
          outstanding_amount: {
            gt: 0,
          },
        },
      },
      [],
      (data) => {
        if (Utils.isNotEmpty(query?.instructPaymentTradeID)) {
          const trade: Trade = data?.trades.find(
            (item: Trade) => item.id === query?.instructPaymentTradeID,
          );
          setFormData((prevState) => ({
            ...prevState,
            trade,
            quote: {
              currency_sell: trade.sold_currency as string,
              currency_buy: trade.bought_currency as string,
              value_date: trade.value_date as string,
              client_ref: activeClient?.cli_reference,
              sell_amount: trade.sold_amount,
              buy_amount: trade.outstanding_amount,
              quote_rate: trade.quote_rate,
            } as QuoteWithClientRef,
          }));
          setStep(1);
          setLoading(false);
        }
      },
      () => setLoading(false),
    );

  const {
    data: { beneficiaries },
    isLoading: beneficiariesLoading,
    error: beneficiaryListFetchError,
  } = useQuery<
    BeneficiariesResult,
    GetBeneficiariesQuery,
    GetBeneficiariesQueryVariables
  >(
    activeClient ? GetBeneficiariesDocument : null,
    {
      client_ref: activeClient?.cli_reference,
      limit: 100,
      // trigger beneficiaries fetch every time a beneficiary is added
    },
    [newBeneficiaryCount],
    ({ beneficiaries }) => {
      const beneficiaryID = query?.beneficiary;

      if (beneficiaries && beneficiaries.length > 0 && beneficiaryID) {
        const selectedBeneficiary = beneficiaries.find(
          ({ id }) => id === beneficiaryID,
        );

        if (selectedBeneficiary) {
          setBeneficiary(selectedBeneficiary);
          setFormData((prevState) => ({
            ...prevState,
            beneficiaries: [
              {
                beneficiary: selectedBeneficiary,
              },
            ],
          }));
        }
      }

      setLoading(false);
    },
    () => setLoading(false),
  );

  const {
    data: currencies,
    isLoading: currenciesAreLoading,
    error: currenciesFetchError,
  } = useQuery<
    GetCurrenciesQuery["getCurrencies"],
    GetCurrenciesQuery,
    GetCurrenciesQueryVariables
  >(activeClient ? GetCurrenciesDocument : null, {
    client_ref: activeClient && activeClient.cli_reference,
  });

  // Handle data fetching errors
  React.useEffect(() => {
    let errorMessage;
    if (
      beneficiaryListFetchError ||
      tradeListFetchError ||
      currenciesFetchError
    ) {
      if (beneficiaryListFetchError) {
        errorMessage = errors.beneficiaryListFetch.message;
      }
      if (tradeListFetchError) {
        errorMessage = errors.tradeFetch.message;
      }
      if (currenciesFetchError) {
        errorMessage = errors.currenciesFetch.message;
      }

      if (errorMessage) {
        toast.notify({
          message: errorMessage,
          status: TOAST_STATUSES.CRITICAL,
        });
      }
    }

    return () => toast.hideNotify();
  }, [beneficiaryListFetchError, tradeListFetchError]);

  // TODO: Remove it!
  React.useEffect(() => {
    if (query?.balance_currency && query?.balance_amount) {
      setDebitSource(DebitSource.Balance);
      setSelectedBalance({
        balance: Number(query.balance_amount),
        currency: query?.balance_currency as string,
      });
    }
  }, [router.query]);

  if (loading || transactionsLoading || currenciesAreLoading) {
    return <Page>Loading...</Page>;
  }

  return (
    <Page title="Make a transfer">
      <Steps nav={<Tab />} goToStep={step}>
        <Steps.Step
          stepTitle="Amount"
          form={
            <QuoteForm
              // TODO:: Move this into a client context
              debitSource={debitSource}
              selectedBalance={selectedBalance}
              previousQuote={formData.quote}
              onComplete={({ quote, trade }) => {
                setFormData((prevState) => ({
                  ...prevState,
                  trade: trade,
                  quote: quote,
                }));
              }}
              isNewTrade={isNewTrade}
              beneficiary={beneficiary}
              currencies={currencies}
            />
          }
        />

        <Steps.Step
          stepTitle="Beneficiary"
          form={
            <BeneficiaryForm
              key={`quote-${formData?.quote?.quote_rate}`}
              quote={formData.quote || ({} as QuoteWithClientRef)}
              existingTrade={formData?.trade}
              beneficiariesLoading={
                transactionsLoading ||
                Utils.isEmpty(formData.quote) ||
                beneficiariesLoading
              }
              beneficiaries={
                beneficiaries as WithRequiredProperty<
                  Beneficiary,
                  "account_name" | "account_number" | "country_code"
                >[]
              }
              isNewTrade={isNewTrade}
              selected={formData.beneficiaries}
              onComplete={(beneficiaries: IBeneficiaryFormData[]) => {
                setFormData((prevState) => ({ ...prevState, beneficiaries }));
                setStep(() => 2);
              }}
              onNewBeneficiaryAdded={() => {
                // trigger beneficiaries fetch every time a beneficiary is added
                setNewBeneficiaryCount(newBeneficiaryCount + 1);
              }}
              hasError={!!tradeListFetchError}
            />
          }
        />

        <Steps.Step stepTitle="Confirm and pay">
          <Steps
            goToStep={
              formData.beneficiaries && formData.beneficiaries.length ? 0 : 1
            }
          >
            <Steps.Step
              form={
                <ConfirmPayForm
                  debitSource={debitSource}
                  stepBack={() => setStep(1)}
                  quote={formData.quote}
                  reason={formData.reason}
                  beneficiaries={formData.beneficiaries}
                  existingTrade={formData?.trade}
                />
              }
            />

            <Steps.Step
              form={
                <PaymentDetails
                  amount={formData.trade?.sold_amount}
                  received={formData.trade?.funds_received}
                  currency={formData.trade?.sold_currency}
                  client={activeClient}
                  beneficiaries={formData.beneficiaries}
                  trade_ref={formData.trade?.reference}
                  account_number={
                    formData.trade?.payment_details?.account_number
                  }
                  account_name={formData.trade?.payment_details?.account_name}
                  bank_name={formData.trade?.payment_details?.bank_name}
                  iban={formData.trade?.payment_details?.iban}
                  sort_code={formData.trade?.payment_details?.sort_code}
                  swift_code={formData.trade?.payment_details?.swift_code}
                />
              }
            />
          </Steps>
        </Steps.Step>
      </Steps>
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ req, res }) => {
  const runWithAmplifyServerContext = withSSRContext(req);

  try {
    const currentUser = await runWithAmplifyServerContext({
      nextServerContext: { request: req, response: res },
      operation: getCurrentUser,
    });

    return serverSideProps(req, {
      props: {
        redirect: "/",
        authenticated: true,
        user: {
          email: currentUser.signInDetails.loginId,
        },
      },
    });
  } catch (err) {
    return serverSideProps(req, {
      props: {
        authenticated: false,
        user: null,
        redirect: "/login",
      },
    });
  }
};

export default Transfer;
