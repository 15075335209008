import React from "react";
import * as yup from "yup";
import { FormProps } from "./types";
import { Input } from "@clear-treasury/design-system";
import { RadioButtonBox } from "#src/core/radio-button-box/RadioButtonBox";
import { useApp } from "#src/ctx/AppProvider";

import type { Client } from "#src/graphql/gql-types";

export const GbpGbSchema = yup.object({
  currency: yup.string().required("Currency is required"),
  country_code: yup.string().required("Country is required"),
  sort_code: yup
    .string()
    .matches(/^[0-9]{6}$/, "Must be 6 digits exactly")
    .required("Sort code is required"),
  bank_name: yup.string().optional(),
  type: yup.string().required("Account type is required"),
  account_name: yup.string().when("type", {
    is: (val: string) => val !== "individual",
    then: (schema) => schema.required("Account name is required"),
  }),
  first_name: yup.string().when("type", {
    is: "individual",
    then: (schema) => schema.required("First name is required"),
  }),
  last_name: yup.string().when("type", {
    is: "individual",
    then: (schema) => schema.required("Last name is required"),
  }),
  account_number: yup
    .string()
    .required("Account number is required")
    .matches(/^[0-9]{8}$/, "Must be 8 digits exactly"),
  alias: yup.string().optional(),
  email: yup.string().email("Must be a valid email address").optional(),
});

export function GbpGbForm({
  handleChange,
  values,
  errors,
  setFieldValue,
}: FormProps): JSX.Element {
  const [activeClient] = useApp<Client>((store) => store.activeClient);

  return (
    <>
      <div className="block w-full mb-8">
        <h2 className="mb-5 text-2xl text-theme-color-on-surface">
          Account type
        </h2>

        <div
          role="radiogroup"
          className="grid gap-6 mb-2 md:grid-cols-2 sm:grid-cols-1"
        >
          <RadioButtonBox
            label="Individual"
            name="individual"
            error={errors}
            checked={values.type === "individual"}
            selectHandler={setFieldValue}
            iconUrl="/next/assets/individual.svg"
          />
          <RadioButtonBox
            label="Business"
            name="company"
            error={errors}
            checked={values.type === "company"}
            selectHandler={setFieldValue}
            iconUrl="/next/assets/company.svg"
          />
        </div>

        {errors?.type?.message && (
          <span className="text-theme-color-error text-sm w-full -mt-1 mb-2 block">
            <span className="sr-only">Error: </span>
            {errors.type.message}
          </span>
        )}
      </div>

      <div className="block w-full mb-8">
        <h2 className="text-2xl text-theme-color-on-surface">
          Account details
        </h2>

        <p className="text-gray-500 text-l">
          Please provide the details of the beneficiary acocunt
        </p>
      </div>

      <div className="mb-8">
        {(!activeClient.is_EEA || values.type !== "individual") && (
          // Show account name by default until something is selected
          <Input
            type="text"
            name="account_name"
            label="Account name"
            placeholder="Enter the beneficiary's account name"
            hint="This should match the name registered on the account you want to pay"
            onChange={handleChange}
            errors={errors}
          />
        )}

        {activeClient.is_EEA && values.type === "individual" && (
          <>
            <div className="grid gap-6 md:grid-cols-2 sm:grid-cols-1">
              <Input
                type="text"
                name="first_name"
                label="First name"
                placeholder="Beneficiary's first name"
                onChange={handleChange}
                errors={errors}
              />
              <Input
                type="text"
                name="last_name"
                label="Last name"
                placeholder="Beneficiary's last name"
                onChange={handleChange}
                errors={errors}
              />
            </div>
            <div className="text-sm text-gray-600">
              This should match the name registered on the account you want to
              pay
            </div>
          </>
        )}
      </div>

      <div className="mb-8">
        <Input
          type="text"
          name="bank_name"
          label="Bank name (optional)"
          placeholder="Enter the bank or payment service provider name"
          onChange={handleChange}
          errors={errors}
        />
      </div>

      <div className="mb-8">
        <Input
          type="text"
          name="account_number"
          label="Account number"
          placeholder="Enter the beneficiary's account number"
          hint="Must be 8 digits exactly"
          onChange={handleChange}
          errors={errors}
        />
      </div>

      <div className="mb-8">
        <Input
          type="text"
          name="sort_code"
          label="Sort Code"
          placeholder="Enter a valid UK sort code"
          onChange={handleChange}
          hint="Must be 6 digits exactly"
          errors={errors}
        />
      </div>

      {values.type === "company" && (
        <div className="mb-8">
          <Input
            type="text"
            name="secondary_reference_data"
            label="Secondary Reference Data (optional)"
            placeholder="Enter the account's secondary reference data"
            onChange={handleChange}
            errors={errors}
          />
        </div>
      )}

      <div className="block w-full mb-8">
        <h2 className="text-2xl text-theme-color-on-surface">
          Additional information
        </h2>
      </div>

      <div className="mb-8">
        <Input
          type="text"
          name="alias"
          label="Beneficiary alias (optional)"
          placeholder="Enter a nickname for the beneficiary"
          hint="This is a reference that we will use to display the beneficiary for you"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="mb-8">
        <Input
          type="text"
          name="email"
          label="Email address (optional)"
          placeholder="Enter an email address for the beneficiary"
          onChange={handleChange}
          errors={errors}
        />
      </div>
    </>
  );
}
