// Responsive Flag component
import * as React from "react";
import { Flag } from "@clear-treasury/design-system";
import resolveConfig from "tailwindcss/resolveConfig";
import userTailwindConfig from "../../../tailwind.config";

const tailwindConfig = resolveConfig(userTailwindConfig);

export const Size = {
  SMALL: "sm",
  MEDIUM: "md",
  LARGE: "lg",
} as const;

type Values<T> = T[keyof T];
interface Props {
  country: string;
  size?: Values<typeof Size>;
}

const ResponsiveFlag: React.FC<Props> = ({ country, size }) => {
  let timerId: NodeJS.Timeout;

  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const breakpoints: { [screenSize: string]: number } = Object.keys(
    tailwindConfig.theme.screens,
  ).reduce((acc, screenSize) => {
    acc[screenSize] = parseInt(
      tailwindConfig.theme.screens[screenSize].replace("px", ""),
    );
    return acc;
  }, {});

  function handleWindowResize() {
    if (timerId) return;

    const shouldUpdateState = ["sm", "md", "lg"].reduce((acc, size) => {
      const condition =
        (windowSize.width < breakpoints[size] &&
          window.innerWidth >= breakpoints[size]) ||
        (windowSize.width > breakpoints[size] &&
          window.innerWidth <= breakpoints[size]);
      acc = acc || condition;
      return acc;
    }, false);

    if (shouldUpdateState) {
      timerId = setTimeout(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
        clearTimeout(timerId);
        timerId = undefined;
      }, 1000);
    }
  }

  if (!size) {
    window.addEventListener("resize", handleWindowResize);
  }

  let flagSize = size;
  if (!size) {
    flagSize =
      windowSize.width < breakpoints.md
        ? "sm"
        : windowSize.width < breakpoints.lg
          ? "md"
          : "lg";
  }

  return <Flag country={country} size={flagSize} />;
};

export default ResponsiveFlag;
