import * as Sentry from "@sentry/nextjs";
import { generateClient } from "aws-amplify/api";
import isDeepEqual from "fast-deep-equal/react";
import { getOperationAST } from "graphql";
import * as React from "react";
import { useMutation as reactQueryMutation, useQueryClient } from "react-query";
import Utils from "../core/Utils";
import { AppContext } from "../ctx/AppProvider";

import type { GraphQLResult } from "@aws-amplify/api-graphql";
import type { Scope } from "@sentry/nextjs";
import type { UseMutateFunction } from "react-query";

type MutationState<Data, OperationVariables> = {
  data: Data;
  doMutation: UseMutateFunction<Data, unknown, OperationVariables, unknown>;
  isLoading: boolean;
  error: any;
};

const client = generateClient();

export const useMutation = <Data, OperationVariables = unknown>(
  query?,
  inputData?: OperationVariables,
  onSuccess?: (data?: Data, inputData?: OperationVariables) => void,
  onError?: (error: any) => void,
): MutationState<Data, OperationVariables> => {
  const store = React.useContext(AppContext);
  const queryClient = useQueryClient();
  const mutationParams = React.useRef({});
  const operation = query ? getOperationAST(query) : null;
  const queryName = operation ? operation.name.value : null;
  const mutationError = React.useRef({});
  const revalidateQuery: Record<string, string[]> = {
    createBeneficiary: ["getBeneficiaries"],
    bookTrade: ["getTrades"],
    archiveBeneficiary: [
      "getBeneficiaries",
      "getPendingApprovals",
      "getTrades",
    ],
    approveBeneficiary: ["getBeneficiaries", "getPendingApprovals"],
    approvePayment: ["getTrades", "getPendingApprovals"],
    denyPayment: ["getTrades", "getPendingApprovals"],
  };

  if (!isDeepEqual(mutationParams.current, inputData)) {
    mutationParams.current = inputData;
  }

  // https://react-query-v3.tanstack.com/guides/mutations
  // https://tkdodo.eu/blog/mastering-mutations-in-react-query
  const {
    data,
    mutate: doMutation,
    isLoading,
    error,
  } = reactQueryMutation<Data, unknown, OperationVariables>(
    [queryName, inputData],
    async (inputData) => {
      const { data } = (await client.graphql({
        query,
        variables: { input: inputData },
      })) as GraphQLResult<Data>;

      if (error) {
        const userEmail = store.get()?.amplifyUser?.email;
        const clientRef = store.get()?.activeClient?.cli_reference;

        Sentry.withScope((scope: Scope) => {
          scope.setFingerprint(query);
          scope.setExtra("variables", JSON.stringify(inputData));
          scope.setExtra("user", userEmail);
          scope.setExtra("client_reference", clientRef);
          scope.setExtra("api response", JSON.stringify(error));
          Sentry.captureException(new Error(`GraphQL Error: ${queryName}`));
        });
        mutationError.current = error;
      }

      return data[queryName];
    },
    {
      onSuccess: async (data, inputData) => {
        if (revalidateQuery[queryName]) {
          for (const query of revalidateQuery[queryName]) {
            await queryClient.invalidateQueries(query);
          }
        }

        onSuccess?.(data, inputData);
      },
      onError: (reason) => {
        const userEmail = store.get()?.amplifyUser?.email;
        const clientRef = store.get()?.activeClient?.cli_reference;

        Sentry.withScope((scope: Scope) => {
          scope.setFingerprint(query);
          scope.setExtra("variables", JSON.stringify(inputData));
          scope.setExtra("user", userEmail);
          scope.setExtra("client_reference", clientRef);
          scope.setExtra("api response", JSON.stringify(reason));
          Sentry.captureException(new Error(`GraphQL Error: ${queryName}`));
        });

        mutationError.current = reason;
        onError?.(reason);
      },
    },
  );

  React.useEffect(() => {
    (async () => {
      if (query && inputData) {
        await doMutation(mutationParams.current as OperationVariables);
      }
    })();
  }, [mutationParams.current, query]);

  return {
    data: data as Data,
    isLoading,
    doMutation,
    error: Utils.isNotEmpty(mutationError.current)
      ? mutationError.current
      : null,
  };
};
